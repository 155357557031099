import Link from 'next/link';
import { BsFillStarFill, BsStarHalf } from 'react-icons/bs';

const maxRating = 5;
function Starts({ rating }: { readonly rating: number }) {
    const fullStarts = Math.floor(rating);
    const halfStarts = rating - fullStarts;
    const emptyStarts = maxRating - fullStarts - (halfStarts >= 0.5 ? 1 : 0);

    return (
        <div className="flex gap-1">
            {[...Array(fullStarts)].map((_, index) => (
                <BsFillStarFill key={index} color="#FBBC04" />
            ))}
            {rating - fullStarts >= 0.5 && <BsStarHalf color="#FBBC04" />}
            {emptyStarts >= 1 && [...Array(Math.floor(emptyStarts))].map((_, index) => <BsFillStarFill key={index} color="#E4E5E7" />)}
        </div>
    );
}

export default function GoogleRating({ className, rating }: { readonly className?: string; rating: number }) {
    return (
        <Link
            id="HM-REVIEW"
            href={
                'https://www.google.com/maps/place/Clidrive/@40.437745,-3.6800324,17z/data=!4m16!1m9!3m8!1s0xd4229418ec48bf7:0x7f9f8f4bfbc367b5!2sClidrive!8m2!3d40.437745!4d-3.6800324!9m1!1b1!16s%2Fg%2F11kbt0945f!3m5!1s0xd4229418ec48bf7:0x7f9f8f4bfbc367b5!8m2!3d40.437745!4d-3.6800324!16s%2Fg%2F11kbt0945f?entry=ttu'
            }
            className={`${className} inline-flex`}
        >
            <div className="flex">
                <div className="bg-blue-500 px-5 py-6 rounded-tl-lg rounded-bl-lg text-[24px] text-white font-800">{rating}</div>
                <div className="flex flex-col bg-white rounded-tr-lg rounded-br-lg justify-center px-4 gap-2">
                    <div className="flex flex-col leading-none">
                        <span className="font-600">Google</span>
                        <span className="text-[12px] text-gray-600">average rating</span>
                    </div>
                    <Starts rating={rating} />
                </div>
            </div>
        </Link>
    );
}
